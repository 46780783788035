import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AdSenseAd from "./AdSenseAd"; // Import your reusable AdSenseAd component
import "./style.css"; // Make sure this file includes your styles

// Import images
import fifa14Image from "./ic/fifa/images/fifa14.webp";
import fifa16Image from "./ic/fifa/images/fifa16.webp";
import fifa19Image from "./ic/fifa/images/fifa19.webp";
import fifa23Image from "./ic/fifa/images/fifa23.webp";

const FIFA = () => {
  const { version } = useParams();
  const [VersionComponent, setVersionComponent] = useState(null);

  // Define FIFA versions with images
  const versions = [
    { name: "FIFA 14", imageUrl: fifa14Image },
    { name: "FIFA 16", imageUrl: fifa16Image },
    { name: "FIFA 19", imageUrl: fifa19Image },
    { name: "FIFA 23", imageUrl: fifa23Image },
  ];

  const loadVersionComponent = async (versionName) => {
    const versionKey = versionName.replace(" ", "").toLowerCase();

    try {
      const { default: Component } = await import(`./ic/fifa/${versionKey}.js`);
      setVersionComponent(() => Component);
    } catch (error) {
      console.error("Error loading component:", error);
      setVersionComponent(null);
    }
  };

  useEffect(() => {
    if (version) {
      loadVersionComponent(version.replace("-", " "));
    } else {
      setVersionComponent(null); // Reset component if no version is specified
    }
  }, [version]);

  return (
    <div className="fifa-page">
      {/* Top Banner Ad (ONLY SHOW IF NO VERSION SELECTED) */}
      {!VersionComponent && <AdSenseAd adSlot="9202427973" adFormat="autorelaxed" />}

      {!VersionComponent ? (
        <div className="version-selection">
          <h2>Pick The Game!</h2>
          <p>Here, you will find the best FIFA mods with direct links!</p>

          <div className="card-container">
            {versions.map((version) => (
              <Link
                to={`/fifa/${version.name.replace(" ", "").toLowerCase()}`}
                key={version.name}
              >
                <div
                  className="card"
                  onClick={() => loadVersionComponent(version.name)}
                >
                  <img
                    src={version.imageUrl}
                    alt={version.name}
                    className="card-image"
                  />
                  <div className="card-overlay">
                    <h3>{version.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          {/* Bottom Ad (ONLY ONE, LESS INTRUSIVE) */}
          <AdSenseAd adSlot="1911358797" adFormat="responsive" />
        </div>
      ) : (
        <div className="selected-version">
          {/* Above Content Ad (Smaller, Non-Intrusive) */}
          <AdSenseAd adSlot="4359258856" adFormat="responsive" />

          <VersionComponent /> {/* Render the dynamically loaded component */}

          {/* Below Content Ad */}
          <AdSenseAd adSlot="1911358797" adFormat="responsive" />

          <Link to="/fifa" className="back-button">
            Back to FIFA Versions
          </Link>
        </div>
      )}
    </div>
  );
};

export default FIFA;
