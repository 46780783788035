import React, { useEffect, useRef } from 'react';

const AdSenseAd = ({
  adSlot,
  adFormat = 'auto',
  fullWidthResponsive = "true",
  style = { display: 'block', width: '100%', height: 'auto' }, // Responsive style
}) => {
  const adRef = useRef(null);

  useEffect(() => {
    const loadAdSense = () => {
      if (window.adsbygoogle) {
        try {
          window.adsbygoogle.push({});
        } catch (e) {
          console.error('AdSense error:', e);
        }
      }
    };

    // Ensure script is added only once
    if (!window.adsbygoogle) {
      const script = document.createElement("script");
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.onload = loadAdSense; // Load ads only after script is ready
      document.body.appendChild(script);
    } else {
      loadAdSense(); // Run AdSense if script is already loaded
    }
  }, []);

  return (
    <ins
      ref={adRef} // Attach ref to ensure element exists
      className="adsbygoogle"
      style={style}
      data-ad-client="ca-pub-7835518300681653"
      data-ad-slot={adSlot}
      data-ad-format={adFormat}
      data-full-width-responsive={fullWidthResponsive}
      data-ad-lazy-load="true"  // Lazy loading enabled
    />
  );
};

export default AdSenseAd;
